<template>
  <svg viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.9771 8.86572C11.0318 8.41504 11.0727 7.96436 11.0727 7.50002C11.0727 7.03568 11.0318 6.585 10.9771 6.13432H13.2852C13.3944 6.57134 13.4627 7.02885 13.4627 7.50002C13.4627 7.97119 13.3944 8.4287 13.2852 8.86572H10.9771ZM9.76848 12.6624C10.1782 11.9044 10.4923 11.085 10.7108 10.2314H12.7252C12.0637 11.3706 11.0141 12.2335 9.76848 12.6624ZM9.59777 8.86572H6.40203C6.33374 8.41504 6.29277 7.96436 6.29277 7.50002C6.29277 7.03568 6.33374 6.57817 6.40203 6.13432H9.59777C9.65923 6.57817 9.70703 7.03568 9.70703 7.50002C9.70703 7.96436 9.65923 8.41504 9.59777 8.86572ZM7.9999 12.9355C7.43313 12.1161 6.97562 11.2079 6.69565 10.2314H9.30414C9.02418 11.2079 8.56667 12.1161 7.9999 12.9355ZM5.26849 4.76862H3.27457C3.92932 3.62635 4.97814 2.76205 6.22449 2.33767C5.81477 3.09563 5.50749 3.91505 5.26849 4.76862ZM3.27457 10.2314H5.26849C5.50749 11.085 5.81477 11.9044 6.22449 12.6624C4.98076 12.2333 3.93341 11.3702 3.27457 10.2314ZM2.71463 8.86572C2.60537 8.4287 2.53709 7.97119 2.53709 7.50002C2.53709 7.02885 2.60537 6.57134 2.71463 6.13432H5.02267C4.96804 6.585 4.92707 7.03568 4.92707 7.50002C4.92707 7.96436 4.96804 8.41504 5.02267 8.86572H2.71463ZM7.9999 2.0577C8.56667 2.87712 9.02418 3.79214 9.30414 4.76862H6.69565C6.97562 3.79214 7.43313 2.87712 7.9999 2.0577ZM12.7252 4.76862H10.7108C10.4971 3.92288 10.1806 3.10649 9.76848 2.33767C11.0249 2.76786 12.0697 3.63508 12.7252 4.76862ZM7.9999 0.671509C4.22373 0.671509 1.17139 3.74434 1.17139 7.50002C1.17139 9.31106 1.89082 11.0479 3.17141 12.3285C3.8055 12.9626 4.55827 13.4656 5.38674 13.8087C6.21521 14.1519 7.10317 14.3285 7.9999 14.3285C9.81093 14.3285 11.5478 13.6091 12.8284 12.3285C14.109 11.0479 14.8284 9.31106 14.8284 7.50002C14.8284 6.60329 14.6518 5.71534 14.3086 4.88686C13.9655 4.05839 13.4625 3.30562 12.8284 2.67153C12.1943 2.03745 11.4415 1.53446 10.6131 1.1913C9.78458 0.848134 8.89663 0.671509 7.9999 0.671509Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconGlobe'
}
</script>
